<template>
    <div style="padding: 10px">
        <el-row :gutter="20">
            <el-col :xs="24" :sm="14" :md="16" style="margin-top: 10px;">
                <h3 class="category" @click="$emit('openCategory', true)">
                    {{ $t('TEXT0201') }}
                </h3>
                <div v-if="categoryName" style="display: inline-block; margin: 0 10px"> /</div>
                <h3 v-if="categoryName" class="category">{{ categoryName }}</h3>
            </el-col>
            <el-col :xs="24" :sm="7" :md="6" style="margin-top: 10px;">
                <el-input :placeholder="$t('TEXT0011')" v-model="input.model.keyword">
                </el-input>
            </el-col>
            <el-col :xs="24" :sm="3" :md="2" style="margin-top: 10px;">
                <el-button icon="el-icon el-icon-search" type="primary" @click="getSubCategories" class="search"/>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :xs="8" :sm="6" :md="4" :lg="3" v-for="(item, index) in subCats" :key="index" class="shop-item-col">
                <el-card shadow="hover" class="shop-item">
                    <div @click="$emit('subCategoryChose', item)">
                        <div style="position: absolute; width: 100%; height: 100%">
                            <img class="shop-logo" :src="item.link ? getApiBase() + item.link : ''" style="">
                            <div class="shop-name category-name">{{ item.name }}</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="24">
                <div style="text-align: center; margin-top: 20px" v-if="subCats.length == 0">適切なデータが見つかりません</div>
            </el-col>
        </el-row>
        <div style="margin-top: 20px; text-align: center">
            <el-pagination
                    background
                    class="subcat-pagination"
                    :current-page.sync="paging.page"
                    :page-size="paging.limit"
                    :total="paging.total"
                    layout="prev, pager, next"
                    @current-change="getSubCategories">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import CategoryApi from "@/api/CategoryApi";
    import EventBus from "@/config/EventBus";

    export default {
        name: "SubCategory",
        props: {
            categoryId: {
                type: Number,
            },
            categoryName: {
                type: String,
                defaults: ''
            }
        },
        mounted() {
            this.getSubCategories()
            EventBus.$on('localeChange', () => {
                this.getSubCategories()
            })
        },
        data() {
            return {
                input: {
                    model: {
                        keyword: null
                    }
                },
                paging: {
                    page: 1,
                    limit: 12,
                    total: null
                },
                subCats: [],
            }
        },
        methods: {
            getSubCategories: function () {
                // ActionHistoryApi.addCategoryViewHistory(this.categoryId)
                CategoryApi.lv2(this.trimObject({
                    categoryId: this.categoryId,
                    keyword: this.input.model.keyword || null,
                    page: this.paging.page,
                    limit: this.paging.limit,
                })).then(resp => {
                    this.subCats = resp.data
                    this.paging.total = resp.total
                })
            }
        }
    }
</script>

<style scoped lang="scss">

    ::v-deep .el-card__body {
        padding: 0px;
    }

    .category {
        margin-top: 10px;
        display: inline-block;
        cursor: pointer
    }

    .category:hover {
        text-decoration: underline;
    }

    .shop-item-col {
        margin: 10px 0px;
    }

    .shop-item {
        padding-bottom: 100%;
        position: relative;
        border: 1px solid #DFE0EB;
        border-radius: 10px;
        cursor: pointer;
    }

    .shop-item .shop-logo {
        width: 50%;
        height: 50%;
        display: block;
        border-radius: 50%;
        margin: 20px auto 20px;
        object-fit: cover;
    }

    @media screen and (max-width: 575px) {
        .shop-item .shop-logo {
            margin: 10px auto 10px;
        }
    }

    .shop-item .shop-name {
        text-align: center;
        width: 75%;
        margin-left: auto;
        margin-right: auto;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis
    }

    .search {
        width: 100%;
    }
</style>
