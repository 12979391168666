<template>
    <div>
        <div class="container view-container">
            <div style="display: flex; justify-content: space-between">
                <div style="display: flex">
                    <div style="align-self: center">{{ $t('TEXT0081') }}</div>
                </div>
                <el-button type="primary" style="max-height: 40px; "
                           @click="()=>{ $router.push('/top')}"
                >{{ $t('TEXT0080') }}
                </el-button>
            </div>

        </div>  
        <div class="container view-container">
            <div>
                {{ helloMessage }}
            </div>
        </div>
        <div class="container view-container" style="background: #44BCFF 95%;"
             v-if="input.data.showCertificate">
            <Certificate/>
        </div>
        
        <div class="container view-container">

            <Notification :title="$t('TEXT0083')"
                          :message="$t('TEXT0084')"
                          :submitText="$t('TEXT0085')"/>
        </div>
        <div class="container view-container ">
            <div class="craft-art-title order-detail-title">
                <h3 style="margin: 10px 0">{{ $t('TEXT0324') }}</h3>
            </div>
            <div class="intro-craft-art">
                <div class="craft-img">
                    <div class="craft-art-title">
                        <p class="craft-header">{{ $t('TEXT0304') }}</p>
                    </div>
                    <div class="craft-body">
                        <div class="left-container" >
                            <div style="position: relative; margin: auto;" class="artist-wrap">
                                <img class="artist-img" src='../../assets/artist.png'>
                                <div class="artist-des">
                                    <p class="artist-title">{{ $t('TEXT0292') }}</p>
                                    <h4 class="artist-name">{{ $t('TEXT0293') }}</h4>
                                    <p style="display: block; position: relative;" class="artist-intro ">{{ $t('TEXT0294') }} <img class="mark" src="../../assets/mark.png" alt=""> </p>
                                    <p class="artist-intro mobile-hidden">{{ $t('TEXT0295') }}</p>
                                    <p class="artist-intro">{{ $t('TEXT0296') }}</p>
                                </div>
                            </div>
                            <div class="">
                                <h4 class="right-text">{{ $t('TEXT0297') }}</h4>
                                <h4 class="right-text">{{ $t('TEXT0298') }}</h4>
                                <h4 class="right-text">{{ $t('TEXT0299') }}
                                </h4>
                            </div>
                        </div>
                        <div class="right-container">
                            <h4 class="right-text paragraph" style="margin-top: 0;">{{ $t('TEXT0300') }} <span class="highlight-text">{{ $t('TEXT0300_1') }}</span>{{ $t('TEXT0300_2') }}</h4>
                            <h4 class="right-text">{{ $t('TEXT0301') }}</h4>
                            <div class="brush-mobile">
                                <Product @setHide="(val) => { hideSuggestShop = val}"/>
                            </div>
                            <h4 style="margin-top: 35px;" class="right-text highlight">{{ $t('TEXT0302') }} <span class="highlight-text">{{ orderPrice }}</span>{{ $t('TEXT0302_1') }}  </h4>
                            <h4 class="right-text highlight">{{ $t('TEXT0303') }} <span class="highlight-text">{{ outDate }}</span>{{ $t('TEXT0303_1') }} </h4>
                            <div class="right-text">
                                <a style="color:#fff" href="#" @click.prevent="popup.paymentPolicy.show = true">{{
                                    $t("TEXT0268")
                                }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="brush-mobile">
                        <Brush @setHide="(val) => { hideSuggestShop = val}"/>
                    </div>
                </div>
                <div style="display: flex; justify-content: center; width: 100% ;">
                    <el-button :disabled="Boolean(orderDetail.id)" type="primary" @click="popup.orderCraftConfirm.show=true" class="request-btn">{{ $t('TEXT0246') }}</el-button>
                </div>

            </div>
        </div>
        <div v-if="orderDetail.id" class="container view-container" >
            <div style="height:100%; margin-left: 10px">
                        <div class="craft-art-title order-detail-title">
                            <h3 style="margin: 10px 0">{{ $t('TEXT0263') }}</h3>
                        </div>
                        <div style="padding: 15px">
                        <el-row>
                            <el-col :span="6" class="detail-label">{{ $t('TEXT0264') }}:</el-col>
                            <el-col :span="6">{{orderDetail.kanjiName}}</el-col>
                            <el-col :span="6" class="detail-label">{{ $t('TEXT0267') }}:</el-col>
                            <el-col :span="6">{{orderDetail.price}}$</el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6" class="detail-label">{{ $t('TEXT0265') }}:</el-col>
                            <el-col :span="6">{{$t(constant.ORDER_STATUS[orderDetail.status].text)}}</el-col>
                            <el-col :span="6" class="detail-label">{{ $t('TEXT0266') }}:</el-col>
                            <el-col :span="6">{{formatDate(orderDetail.estimateDelivery)}}</el-col>
                        </el-row>
                        <el-row v-if="orderDetail.status === constant.ORDER_STATUS.DONE.value" style="display: flex;flex-direction:column; align-items: start; margin-top: 10px">
                                <el-row :span="2" class="detail-label">{{ $t('TEXT0291') }}:</el-row>
                                <el-row :span="22" class="result-container">
                                    <img :src="this.orderDetail.path" alt="">
                                    <el-button :loading="loading.download" class="btn-download-result" @click="handleDownloadResult">{{ $t('TEXT0250') }}</el-button>
                                </el-row>
                        </el-row>
                        </div>
            </div>
        </div>
        <div class="container view-container" :class="{ hide : (hideSuggestShop || view.showing == 'NONE' )}">
            <!--             v-if="(viewComponent || 'ShopList') == 'ShopList'">-->
            <SuggestShop @setHide="(val) => { hideSuggestShop = val}"/>
        </div>
        <div class="container view-container"  v-if="view.showing != 'NONE'">
            <component :is="viewComponent || 'ShopList'"
                       :categoryId="view.categoryId"
                       :categoryName="view.categoryName"
                       :subCategoryName="view.subCategoryName"
                       :subCategoryId="view.subCategoryId"
                       @categoryChose="(category)=>{view.showing='STORE_AND_SUBCATEGORY'; view.categoryId = category.id; view.categoryName = category.name }"
                       @subCategoryChose="(subCategory)=>{view.showing='STORE_ONLY'; view.subCategoryId = subCategory.id; view.subCategoryName = subCategory.name; addSubcategoryViewHistory(subCategory.id) }"
                       @openCategory="()=>{view.categoryId = null; view.subCategoryId = null; view.showing = 'FULL'}"
                       @openSubcategory="()=>{view.subCategoryId = null; view.showing = 'STORE_AND_SUBCATEGORY'}"
            />
        </div>
        <ServiceRating v-model="popup.serviceRating.show"/>
        <PaymentPolicy :cost="orderPrice" :date="outDate" v-model="popup.paymentPolicy.show" />

    <!-- QR code -->
    <el-tooltip class="qrcode-tooltip" placement="top">
      <div slot="content">{{ $t('TEXT0252') }}</div>
      <el-button
        class="btn-qr"
        type="primary"
        @click="
          dialogTableVisible = true;
          getQRcode();
          getTattooQRcode();
        "
        ><i class="fa fa-qrcode fa-2x" aria-hidden="true"></i
      ></el-button>
    </el-tooltip>
        <el-dialog width="300px" height="1000px"  :title="$t('TEXT0251')" :visible.sync="dialogTableVisible">
          <!-- <el-button class="switch-qr" @click="switchQRcode()">{{ qrSwitchContent }}</el-button> -->
          <div v-if="!isOtherQR" class="">
            <div class="">
                    <p class="qr-header"> {{ $t('TEXT0335') }}</p>
                    <p class="qr-header mb-0">{{ $t('TEXT0310') }}</p>
            </div>
            <img :src="urlImg" alt="">
            <p class="qr-header fs-16 text-align mb-0">{{ $t('TEXT0334') }}</p>
            <p class="qr-header fs-16 text-align">{{ $t('TEXT0334_1') }}</p>
              <el-button class="download-btn" width="100%" type="primary" icon="el-icon-download"><a href="#" @click.prevent="downloadPNG"
                style="text-decoration: none; color: white; margin-left: 3px" class="btn-print">{{ $t('TEXT0250') }}</a></el-button>
                <el-button class="switch-qr" type="text"  @click="switchQRcode()">{{ $t('TEXT0332') }}</el-button>
          </div>
          <div v-if="isOtherQR" class="">
            <div class="">
                    <p class="qr-header">{{ $t('TEXT2048') }}</p>
                    <p class="qr-header mb-0">{{ $t('TEXT0310') }}</p>
            </div>
              <img :src="urlTattooQR" alt="">
              <p class="qr-header fs-16 text-align mb-0">{{ $t('TEXT0333') }}</p>
              <p class="qr-header fs-16 text-align">{{ $t('TEXT0333_1') }}</p>
              <el-button class="download-btn" type="primary" icon="el-icon-download">
                <a href="#" @click.prevent="downloadTattooQRCode"
                style="text-decoration: none; color: white; margin-left: 3px" class="btn-print">{{ $t('TEXT0250') }}</a>
              </el-button>
              <el-button class="switch-qr" type="text" @click="switchQRcode()">{{ $t('TEXT0332') }}</el-button>
          </div>
        </el-dialog>
        <!-- QR code -->
        <RequestDialogConfirm
                v-model="popup.orderCraftConfirm.show"
                :message="$t('TEXT2047', { cost: orderPrice })"
                @close="popup.orderCraftConfirm.show = false"
                ></RequestDialogConfirm>
    </div>
    
</template>

<script>
import Certificate from "@/components/kanji/Certificate";
import Notification from "@/components/kanji/Notification";
import ServiceRating from "@/components/kanji/ServiceRating";
import AreaSelector from "@/components/kanji/AreaSelector";
import SuggestShop from "@/components/kanji/convert/SuggestShop";
import ShopList from "@/components/kanji/convert/ShopList";
import ConfigApi from "@/api/ConfigApi";
import Category from "@/components/kanji/convert/Category";
import SubCategory from "@/components/kanji/convert/SubCategory";
import GuestApi from "@/api/GuestApi";
import EventBus from "@/config/EventBus";
import axios from "axios";
import ActionHistoryApi from "@/api/ActionHistoryApi";
import RequestDialogConfirm from "@/components/RequestDialogConfirm";
import 'element-ui/lib/theme-chalk/display.css';
import { getDetailOrder, downloadOrderImage } from '@/api/Order'
import constant from '@/config/constant'
import moment from 'moment'
import downloadFile from '@/mixins/downloadFile'
import Brush from '../../components/Brush.vue'
import Product from '../../components/Product.vue'

import PaymentPolicy from '../../views/payment/PaymentPolicy.vue'


export default {
    name: "KanjiConvertCertificate",
    components: {
        ShopList,
        SuggestShop,
        AreaSelector,
        ServiceRating,
        Notification,
        Certificate,
        Category,
        SubCategory,
        RequestDialogConfirm,
        Brush,
        PaymentPolicy,
        Product

    },
    mixins: [downloadFile],
    computed: {
        viewComponent() {
            switch (this.view.showing) {
                case 'FULL': {
                    if (this.view.config == 'FULL') {
                        return 'Category'
                    } else if (this.view.config == 'STORE_AND_SUBCATEGORY') {
                        return 'SubCategory'
                    } else {
                        return null
                    }
                }
                case 'STORE_AND_SUBCATEGORY': {
                    if (this.view.config == 'FULL' || this.view.config == 'STORE_AND_SUBCATEGORY') {
                        return 'SubCategory'
                    } else {
                        return null
                    }
                }
                default: {
                    console.log("return null")
                    return null
                }
            }
        },
        helloMessage() {
            if (!this.input.data.profile.fullName) {
                return ''
            } else if (JSON.parse(localStorage.getItem('lang') || '{}').locale == 'ja-jp') {
                return this.input.data.profile.kanji + "SAN ("
                        + this.input.data.profile.fullName + "様) " + "、こんにちは！"
            } else {
                return "Hello " + this.input.data.profile.kanji + "SAN (Dear " + this.input.data.profile.fullName + ") !"
            }
        },
        qrSwitchContent(){
            return this.isOtherQR ? 'Click here to get QR code for Customer questionnaire' : 'Click here to get QR code for Tattoo sticker machine'
        }
    },
    mounted() {
        this.getConfig()
        this.getProfile()
        const showRating = !!localStorage.getItem('showRating')
        this.input.data.showCertificate = showRating
        this.popup.serviceRating.show = showRating
              
        EventBus.$on('localeChange', () => {
            if (this.$route.path == '/kanji/convert/certificate') {
                this.getProfile()
                
            }
        });
    },
    data() {
        return {
            constant,
            input: {
                data: {
                    showCertificate: false,
                    profile: {
                        kanji: '',
                        gender: null
                    },
                },
            },
            popup: {
                serviceRating: {
                    show: false
                },
                areaSelector: {
                    show: false
                },
                orderCraftConfirm:{
                    show: false
                },
                paymentPolicy: {
                    show: false
                }
            },
            // viewConfig: null,
            view: {
                showing: null,
                config: null,
                categoryId: null,
                categoryName: null,
                subCategoryId: null,
                subCategoryName: null,
                
            },
            hideSuggestShop: false,
            dialogTableVisible: false,
            payload: {},
            orderPrice:30,
            outDate: 5,
            orderDetail:{},
            urlImg: null,
            urlTattooQR: null,
            loading: {
                download: false,
            },
            isOtherQR: true,
        }
    },

    methods: {
        getProfile: function () {
            GuestApi.getProfile().then(resp => {
                this.input.data.profile = resp.data
                this.payload = {
                     "id" : this.input.data.profile.id,
                     "kanjiName" : this.input.data.profile.kanji,
                }
                localStorage.setItem('id', this.input.data.profile.id )
                if (resp.data.rating == 0) {
                    this.popup.serviceRating.show = true
                    this.input.data.showCertificate = true
                }
                else{
                    this.popup.serviceRating.show = false
                    this.input.data.showCertificate = false
                }
            })
            .then(()=>{
                this.getDetailOrder()
            })
            .catch(err => {
                console.log(err)
                localStorage.removeItem('token')
                localStorage.removeItem('id')
                EventBus.$emit('updateNavBar')
                this.$router.replace("/")
            })
        },
        getConfig: function () {
            ConfigApi.getConfig().then(resp => {
                this.view.showing = resp.data.view
                this.view.config = resp.data.view
                this.orderPrice = resp.data.orderPrice
                this.outDate = resp.data.outDate

            }).catch(err => {
                console.log(err);
            })
        },
        addSubcategoryViewHistory: function (id) {
            ActionHistoryApi.addCategoryViewHistory(id)
        },
        getDetailOrder() {
            getDetailOrder({idNo: this.payload.id, kanjiName: this.payload.kanjiName}).then((res)=>{
                this.orderDetail = res.data
            }).catch(err => {
                console.log(err);
            })
        },
        payment(){},
        downloadPNG: function () {
            axios.post(location.protocol + '//' + process.env.VUE_APP_API_PREFIX + location.hostname  + process.env.VUE_APP_API_PORT + "/api/kanji/qr-code/questionnaire/download", this.payload, {
                headers: {
                    Authorization: localStorage.getItem('token'),
                },
                responseType: 'blob',
            }).then((response) => {
                var blob = new Blob([response.data], { type: "image/png" });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'survey-QR.png'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch((err)=>{
                console.log(err);
            })
        },
        downloadTattooQRCode: function () {
            axios.post(location.protocol + '//' + process.env.VUE_APP_API_PREFIX + location.hostname  + process.env.VUE_APP_API_PORT + "/api/kanji/qr-code/tattoo/download", this.payload, {
                headers: {
                    Authorization: localStorage.getItem('token'),
                },
                responseType: 'blob',
            }).then((response) => {
                var blob = new Blob([response.data], { type: "image/png" });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'sticker-QR.png'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch((err)=>{
                console.log(err);
            })
        },
        getQRcode: function() { 
            axios.post(location.protocol + '//' + process.env.VUE_APP_API_PREFIX + location.hostname + process.env.VUE_APP_API_PORT + "/api/kanji/qr-code/questionnaire/generate", this.payload, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'content-type': 'application/json',
                    'accept': 'image/png'
                },
                responseType: 'blob',
            }).then((response) => {
                // let a = btoa(String.fromCharCode.apply(null, response.data.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ")));
                // let b = 'data:image/jpeg;base64,' + a;
                const urlCreator = window.URL || window.webkitURL
                this.urlImg = urlCreator.createObjectURL(response.data)
            }).catch((err)=>{
                console.log(err);
            })
        },
        getTattooQRcode: function() { 
            axios.post(location.protocol + '//' + process.env.VUE_APP_API_PREFIX + location.hostname + process.env.VUE_APP_API_PORT + "/api/kanji/qr-code/tattoo/generate", this.payload, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'content-type': 'application/json',
                    'accept': 'image/png'
                },
                responseType: 'blob',
            }).then((response) => {
                const urlCreator = window.URL || window.webkitURL
                this.urlTattooQR = urlCreator.createObjectURL(response.data)
            }).catch((err)=>{
                console.log(err);
            })
        },
        formatDate(value, format = 'YYYY/MM/DD'){
            return moment(value).format(format)
        },
        handleDownloadResult(){
            this.loading.download = true
            downloadOrderImage(this.orderDetail.id)
                .then((res)=>{
                    this.downloadFile(res, this.orderDetail.imageName)
                }).catch((err)=>{
                console.log(err);
                }).finally(()=>{
                    this.loading.download = false
                })
        },
        switchQRcode(){
            this.isOtherQR = !this.isOtherQR
        }
    }
}
</script>

<style scoped lang="scss">

.view-container {
    background-color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
}
.craft-img{
    display: flex;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 90%;
    margin-top: 15px;
    background-image: url('../../assets/background-craft.png');
    height: -webkit-fill-available;

}
.craft-header{
    font-size: 36px;
    font-weight: 900;
    font-family: inherit;
    color: #fff;
    margin: 20px;
    letter-spacing: 2px;
}
.artist-img{
    width: 100%;
    height: auto;
    filter: brightness(0.6);
}
.craft-body{
    display: flex;
}

.intro-craft-art{
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.artist-des{
    width: 90%;
    filter: brightness(1.1);
    position: absolute;
    bottom: 0;
    padding: 10px;
    margin-bottom: 10px;
}
.artist-title{
    color: #d89b23;
    font-size: 24px;
    font-weight: bolder;
    -webkit-text-stroke: 0.2px #000;
    margin: 0;
}
.brush-wrap{
    position: relative;
    height: 300px;
}
.paragraph{
    margin-top: 30px;
}
.brush-img{
    height: 100%;
    width: auto;
}
.brush{
    display: flex; 
    justify-content: space-between;
}
.artist-intro{
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin:  10px 0;
    -webkit-text-stroke: 0.3px #000;
}
.artist-name{
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    margin:  8px 0;
}
.right-text{
    color: #dcdfe6;
    font-size: 16px;
    margin:  8px 0;
    font-weight: 100;
    line-height: 32px;
}
.highlight{
    font-weight: 600;
    font-size: 18px;
}
.paragraph {
    margin-top: 30px;
}
.highlight-text{
    color: #e94b19 ;
    font-weight: bold;
    font-size: 20px;
}
@media screen and (max-width: 535px)  {
    .paragraph{
        margin-top: 0px;
    }
    .mark{
        margin-left: 4px;
        position: absolute;
        width: 16px !important;
        height: 16px !important;
        bottom: 2px;
    }
    .highlight-text{
    color: #e94b19 ;
    font-weight: bold;
    font-size: 17px;
    }
}
@media screen and (min-width: 535px) and (max-width: 1199px) {
    .left-container{
        display: flex;
        flex-direction: column;
    }
    .artist-wrap{
        width: 80%;
    }
    .artist-img{
        width: 90% ;
        height: auto;
        margin: auto;
    }
    
    .artist-des{
        width: 90%;
        filter: brightness(1.1);
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 10px;
        margin-bottom: 10px;
    }
    .highlight-text{
        color: #e94b19 ;
        font-weight: bold;
        font-size: 17px;
    }
    .paragraph{
        margin-top: 20px;
    }
}
@media screen and (max-width: 1199px) { // mobile
    .craft-img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    height: -webkit-fill-available;
    }
    .request-btn{
    margin-top: 15px;
    height: 60px;
    width: 100%;
    min-width: 280px;
    }
    .brush{
        display: none; 
        justify-content: space-between;
    }
    .craft-header{
        font-size: 23px;
        font-weight: 900;
        font-family: inherit;
        color: #fff;
        margin: 20px;
        letter-spacing: 2px;
    }
    .craft-body{
        display: flex;
        flex-direction: column;
    }
    .artist-title{
        font-size: 18px;
    }
    .artist-name{
        font-size: 16px;
    }
    .artist-intro{
        font-size: 11px;
        margin: 0;
    }
    .artist-des{
        margin: 0;
    }
    .mobile-hidden{
        display:none;
    }
    .right-container{
        margin: 0 20px;
        padding: 0 !important;
    }
    .right-text{
        color: #dcdfe6;
        font-size: 13px;
        margin:  8px 0;
        font-weight: 500;
        line-height: 20px;
        width: 100%;
    }
    .highlight{
        font-weight: 600;
        font-size: 15px;
    }
    .craft-header{
        font-size: 20px;
        font-weight: 600;
        font-family: inherit;
        color: #fff;
        margin: 10px;
        letter-spacing: 2px;
    }
    .paragraph{
        margin-top: 0px;
    }
}
.hide {
    display: none;
}

.btn-qr {
    position: fixed;
    top: 30em;
}
.download-btn{
    margin: auto;
   display: block;
    padding: 12px;
}
img {
    width: 250px;
    height: 250px;
}

.craft-art-card{
    padding: 0 30px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
}
.craft-image{
    height: 200px;
    padding: 0 20px;
}
.craft-card{
    margin-bottom: 25px;
}
.left-container{
    padding: 20px;
    flex: 1;
    position: relative;
}
.right-container{
    padding: 10px 30px 30px 30px;
    flex: 1;
    display: flex;
    flex-direction: column;
}
.request-btn{
    margin-top: 15px;
    height: 60px;
    width: 90% ;
    font-size: 16px;
    font-weight: bolder;
}
.left-img{
    width: 100%;
    height: auto;
}
.order-detail-title{
    margin: 0px 10px;
    border-bottom: 1px solid #dcdfe6;
}
.detail-label{
    color: #606266;
    font-weight: 700;
    margin-bottom: 10px;
}
.result-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    img{
        width: 300px;
        height: 200px;
        border-radius: 6px;
    }
    .btn-download-result{
        border-radius: 60px;
        border: none;
        color: #fff;
        background: #44BCFF;
        width: 300px;
    }

}
.mark{
    margin-left: 4px;
    position: absolute;
    width: 22px;
    height: 22px;
    bottom: 2px;
}
.qrcode-tooltip{
    width: 74px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    margin-left: 10px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.qrcode-tooltip:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
.qr-footer{
    display: flex;

}
::v-deep .el-dialog__body{
    padding-top:10px;
}
.qr-header{
    margin-top: 0;
    margin-bottom: 8px;
}
.text-align{
    text-align: center;
}
.switch-qr {
    color: #44bcff;
    border: none;
    background-color: transparent !important;
    font-size: 16px;
    font-weight: 700;
    padding: 12px 0;
    margin: auto;
    display: block;
}
.mb-0{
    margin-bottom: 0;
}
.fs-16{
    font-size: 16px;
}
</style>
