<template>
    <div style="padding: 10px">
        <h3 class="category">{{ $t('TEXT0201') }}</h3>
        <el-row :gutter="20">
            <el-col :xs="8" :sm="6" :md="4" :lg="3" v-for="(item, index) in categories" :key="index" class="shop-item-col"
                    :class="index | shopItemClass">
                <el-card shadow="hover" class="shop-item">
                    <div @click="$emit('categoryChose', item)">
                        <div style="position: absolute; width: 100%; height: 100%">
                            <img class="shop-logo" :src="item.link ? getApiBase() + item.link : ''" style="">
                            <div class="shop-name category-name">{{ item.name }}</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import CategoryApi from "@/api/CategoryApi";
    import EventBus from "@/config/EventBus";

    export default {
        name: "Category",
        filters: {
            shopItemClass: function (index) {
                if (index % 6 == 0) {
                    return 'left-pc'
                } else if (index % 6 == 5) {
                    return 'right-pc'
                } else if (index % 2 == 0) {
                    return 'left-mobile'
                } else {
                    return 'right-mobile'
                }
            }
        },
        mounted() {
            this.getCategories()
            EventBus.$on('localeChange', () => {
                this.getCategories()
            })
        },
        data() {
            return {
                categories: []
            }
        },
        methods: {
            getCategories: function () {
                CategoryApi.lv1().then(resp => {
                    this.categories = resp.data
                })
            }
        }
    }
</script>

<style scoped lang="scss">

    ::v-deep .el-card__body {
        padding: 0px;
    }

    .category {
        margin-top: 10px;
        display: inline-block;
        cursor: pointer
    }

    .category:hover {
        text-decoration: underline;
    }

    .shop-item-col {
        margin: 10px 0px;
    }

    .shop-item {
        padding-bottom: 100%;
        position: relative;
        border: 1px solid #DFE0EB;
        border-radius: 10px;
        cursor: pointer;
    }

    .shop-item .shop-logo {
        width: 50%;
        height: 50%;
        display: block;
        border-radius: 50%;
        margin: 20px auto 20px;
        object-fit: cover;
    }

    @media screen and (max-width: 480px) {
        .shop-item .shop-logo {
            margin: 10px auto 10px;
        }
    }

    .shop-item .shop-name {
        text-align: center;
        width: 75%;
        margin-left: auto;
        margin-right: auto;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis
    }
</style>
