import axios from "@/config/axios";

export default {
    getSuggestShop: function (data) {
        return axios({
            url: "/api/shop/guest/suggest",
            method: 'post',
            data: data
        });
    },
    searchShop: function (data) {
        return axios({
            url: "/api/shop/guest/search",
            method: 'post',
            data: data
        });
    },
}
