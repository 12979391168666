<template>
    <el-dialog :visible="value"
               :show-close="false">
        <div style="text-align: center">
            <h3 class="title title33" >{{ $t('TEXT0086') }}</h3>
            <img class="rating-emotion"
                 :src="require('@/assets/certificate/' +rateIcons[input.model.rating])"/>
            <div class="rating-message">{{ rateMessages[input.model.rating] }}</div>
            <el-rate class="rating-star"
                     v-model="input.model.rating"/>

            <el-button type="primary"
                       class="btn-rating"
                       :disabled="input.model.rating < 1 || input.model.rating > 5"
                       :loading="loading.rating"
                       @click="rateService">{{ $t('TEXT0088') }}
            </el-button>
        </div>

    </el-dialog>
</template>

<script>
import KanjiApi from "@/api/KanjiApi";

export default {
    name: "ServiceRating",
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            input: {
                model: {
                    rating: 5
                }
            },
            loading: {
                rating: false
            },
            rateMessages: [
                this.$t('TEXT0123'),
                this.$t('TEXT0120'),
                this.$t('TEXT0121'),
                this.$t('TEXT0122'),
                this.$t('TEXT0123'),
                this.$t('TEXT0124'),
            ],
            rateIcons: [
                'rate4.png',
                'rate1.png',
                'rate2.png',
                'rate3.png',
                'rate4.png',
                'rate5.png',
            ]
        }
    },
    methods: {
        rateService: function () {
            this.loading.rating = true
            KanjiApi.rateService(Object.assign({key: localStorage.getItem('guestKey')}, this.input.model)).then(resp => {
                console.log(resp)
                this.$emit('input', false)
            }).catch(resp => {
                console.log(resp)
            }).finally(() => {
                this.loading.rating = false
                localStorage.removeItem('showRating')
            })
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
    width: 400px;
}

::v-deep .el-rate__icon{
    font-size: 32px;
    //margin-bottom: 30px;
}

.title{
    margin-top: 0px
}

.rating-emotion{
    display: block;
    margin: auto;
    width: 6em;
    height: 6em
}
.rating-message{
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em
}

.rating-star{
    text-align: center;
    margin-top: 1em;
    margin-bottom: 2em
}

.btn-rating{
    margin-top: 0px;
    margin-bottom: 0px
}
</style>
