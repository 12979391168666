import axios from "@/config/axios";

export default {
    lv1: function (data) {
        return axios({
            url: "/api/category/guest/lv1",
            method: 'post',
            data: data
        });
    },
    lv2: function (data) {
        return axios({
            url: "/api/category/guest/lv2",
            method: 'post',
            data: data
        });
    },

}
