export default {
  data() {
    return {

    }
  },
  methods: {
    downloadFile(data, nameFile) {
      const fileURL = window.URL.createObjectURL(new Blob([data]))
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', nameFile)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    downloadFileUrl(url, nameFile) {
      var dl = document.createElement('a')
      dl.setAttribute('href', url)
      dl.setAttribute('download', nameFile)
      dl.click()
      dl.remove()
    }
  }
}
