import axios from "@/config/axios";
import constant from "@/config/constant";

export default {
    getShopViewHistory: function (data) {
        return axios({
            url: "/api/action-history/get-shop-view-history-for-guest",
            method: 'post',
            data: data
        });
    },
    addShopViewHistory: function (data) {
        return axios({
            url: "/api/action-history/add-action-history",
            method: 'post',
            data: Object.assign({
                type: constant.ACTION_TYPE.VIEW_SHOP,
                targetId: data
            })
        });
    },
    addCategoryViewHistory: function (data) {
        return axios({
            url: "/api/action-history/add-action-history",
            method: 'post',
            data: Object.assign({
                type: constant.ACTION_TYPE.VIEW_CATEGORY,
                targetId: data
            })
        });
    },
}
