import axios from "@/config/axios";

export default {
    areaProvince: function (data) {
        return axios({
            url: "/api/area/guest/area-province",
            method: 'post',
            data: data
        });
    },
    area:function (data){
        return axios({
            url: "/api/area/guest/all",
            method: 'post',
            data: data
        });
    }


}
