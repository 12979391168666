<template>
    <el-dialog :visible="value"
               :title="$t('TEXT0090')"
               @close="$emit('input', false)"
               style="width:100% !important;">
        <div style="max-height: 50vh; overflow-y: auto">
            <el-collapse v-model="activeNames"
                         accordion
            >
                <el-collapse-item v-for="(item, index) in areas"
                                  :key="index"
                                  :title="item.name"
                                  :name="item.id">
                    <el-radio slot="title"
                              :label="item.id"
                              v-model="input.model.areaId"
                              @click.native.stop="input.model.provinceId=null"
                              @change="(value) => {item.provinces.forEach(item => item.checked = value); item.__ob__.dep.notify() }">
                        {{ item.name }}
                    </el-radio>
                    <el-radio v-for="(item2, index2) in item.provinces"
                              style="display: block; margin-left: 20px; margin-top: 5px"
                              v-model="input.model.provinceId"
                              @change="()=>{item.checked = false; item.__ob__.dep.notify(); }"
                              :disabled="input.model.areaId != item.id"
                              :key="index2"
                              :label="item2.id">{{ item2.name }}
                    </el-radio>
                </el-collapse-item>
            </el-collapse>
        </div>
        <div style="text-align: center; margin-top: 20px">
            <el-button type="primary"
                       @click="$emit('ok', input.model); $emit('input', false); $emit('name',placeName)">
                {{ $t('TEXT0089') }}
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
import AreaApi from "@/api/AreaApi";
import EventBus from "@/config/EventBus";

export default {
    name: "AreaSelector",

    mounted() {
        this.getAreaProvince()
        EventBus.$on('localeChange', () => {
            this.getAreaProvince()
        })
    },
    computed: {
        selectedProvince: function () {
            let result = [];
            for (let i = 0; i < this.areas.length; i++) {
                for (let j = 0; j < this.areas[i].provinces.length; j++) {
                    if (this.areas[i].provinces[j].checked) {
                        console.log("push")
                        result.push(this.areas[i].provinces[j].id)
                    }
                }
            }
            console.log(result)
            return result;
        },
        placeName() {
            for (let i = 0; i < this.areas.length; i++) {
                if (this.areas[i].id == this.input.model.areaId) {
                    if (this.input.model.provinceId) {
                        for (let j = 0; j < this.areas[i].provinces.length; j++) {
                            if (this.areas[i].provinces[j].id == this.input.model.provinceId) {
                                return this.areas[i].provinces[j].name
                            }
                        }
                    } else {
                        return this.areas[i].name;
                    }
                }
            }
            return null;
        },
        activeNames() {
            return [(this.areas.find(item => item.id == this.input.model.areaId) || {}).id]
        }
    },
    watch: {
        // value: function (val) {
        //     if (!val) {
        //         this.setNull(this.input.model)
        //     }
        // }
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            input: {
                model: {
                    areaId: null,
                    provinceId: null,
                    // placeName: null
                },
                data: {
                    // activeNames: []
                }
            },

            areas: [],

        }
    },
    methods: {
        getAreaProvince: function () {
            AreaApi.areaProvince().then(resp => {
                this.areas = resp.data.map(item => Object.assign(item, {provinces: JSON.parse(item.provinces)}))
                // for (let i = 0; i < this.areas.length; i++) {
                //     this.input.data.activeNames.push(i)
                // }
            })
        },
        resetData: function () {
            console.log("reset data")
            this.setNull(this.input.model)
        }
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 992px) { // mobile
    ::v-deep .el-dialog {
        width: 90%;
    }
}
</style>
