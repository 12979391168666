<template>
    <div>
        <el-row :gutter="20" style="margin: 0">
            <el-col :xs="24" :md="12" style="margin-top: 10px;">
                <h3 class="category" @click="$emit('openCategory', true)">
                    {{ $t('TEXT0201') }}
                </h3>
                <div v-if="categoryName" style="display: inline-block; margin: 0 10px"> /</div>
                <h3 v-if="categoryName" class="category"
                    @click="$emit('openSubcategory', true)">
                    {{ categoryName }}
                </h3>
                <div v-if="subCategoryName" style="display: inline-block; margin: 0 10px"> /</div>
                <h3 v-if="subCategoryName" class="category">{{ subCategoryName }}
                </h3>
            </el-col>
            <el-col :xs="24" :sm="24" :md="5" style="margin-top: 10px; ">
                <div class="area-row">
                    <el-input v-model="input.model.placeName"
                              @clear="input.model.provinceId = null; input.model.areaId = null"
                              clearable
                              :placeholder="$t('TEXT0204')"
                              @focus="popup.areaSelector.show = true"
                              inputmode=""
                              readonly
                              class="area"
                    />
                    <el-button class="btn-inside" :class="{ hide: hide }" size="mini" icon="el-icon-delete" circle
                               @click="removeSelectedArea"></el-button>
                </div>
            </el-col>
            <el-col :xs="24" :sm="18" :md="5" style="margin-top: 10px;">
                <el-input :placeholder="$t('TEXT0011')" v-model="input.model.keyword">
                </el-input>
            </el-col>
            <el-col :xs="24" :sm="6" :md="2" style="margin-top: 10px;">
                <el-button icon="el-icon el-icon-search" type="primary" @click="getShop" class="search"/>
            </el-col>
        </el-row>
        <el-row>
            <el-col v-for="(item, index) in shopParts" :key="index" :xs="24" :md="12">
                <div v-for="(item2, index2) in shopParts[index]" :key="index2">

                    <el-card shadow="hover" :body-style="{ padding: '0px' }" style="margin: 10px;">
                        <el-row style="margin: 0">
                            <el-col :xl="6" :lg="8" :md="9" :sm="6" :xs="6" style="padding: 0">
                                <div class="shop-image-wrapper">
                                    <Carousel :perPage="1"
                                              :controls="true" :indicators="true"
                                              :paginationEnabled="true"
                                              class="shop-image-slider">
                                        <Slide v-for="(gg, index) in item2.thumbs" :key="index" style="height: 100%">
                                            <img class="shop-image" :src="gg.link ? getApiBase() + gg.link : ''"/>
                                        </Slide>
                                    </Carousel>

                                </div>
                            </el-col>
                            <el-col :xl="18" :lg="16" :md="15" :sm="18" :xs="18" style="padding: 5px;">
                                <el-row style="margin: 0">
                                    <el-col :span="16" style="padding: 0px">
                                        <div style="height: 1.5em">
                                            <a class="card-wrapper" href="#"
                                               @click.prevent="popup.openShopWarning.item = item2; popup.openShopWarning.show=true">
                                                <h3 class="shop-name">
                                                    {{ item2.name }}</h3>
                                            </a>
                                        </div>
                                        <div style="height: 1.5em">
                                            <div class="shop-code">
                                                {{ item2.code }}
                                            </div>
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="shop-overseaSale">
                                            {{ item2.overseaSale ? $t('TEXT0116') : $t('TEXT0117') }}
                                        </div>
                                    </el-col>
                                    <el-col :span="24" class="description1">
                                        <div class="divider" style="margin: 5px"></div>
                                        <div style="height: 72px">
                                            <el-tooltip :content="item2.description">
                                                <div class="shop-description" style="-webkit-line-clamp: 3;">
                                                    {{ item2.description }}
                                                </div>
                                            </el-tooltip>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="24" class="description2">
                                <div class="divider"></div>
                                <div style="max-height: 110px; padding: 5px">
                                    <el-tooltip :content="item2.description">
                                        <div class="shop-description" style="-webkit-line-clamp: 4;">
                                            {{ item2.description }}
                                        </div>
                                    </el-tooltip>
                                </div>
                            </el-col>
                        </el-row>
                        <!--                        </a>-->
                    </el-card>
                </div>
            </el-col>
        </el-row>
        <div style="margin-top: 20px; text-align: center">
            <el-pagination
                    background
                    :current-page.sync="paging.page"
                    :total="paging.total"
                    layout="prev, pager, next"
                    @current-change="getShop">
            </el-pagination>
        </div>
        <AreaSelector
                @ok="(data)=> {input.model.areaId = data.areaId, input.model.provinceId = data.provinceId; getShop}"
                @name="placeName => input.model.placeName = placeName"
                v-model="popup.areaSelector.show"
                ref="areaSelector"/>
        <OpenShopWarning v-model="popup.openShopWarning.show" @ok="viewShop(popup.openShopWarning.item)"/>
    </div>
</template>

<script>
import ShopApi from "@/api/ShopApi";
import AreaApi from "@/api/AreaApi";
import {Carousel, Slide} from "vue-carousel";
import ActionHistoryApi from "@/api/ActionHistoryApi";
import EventBus from "@/config/EventBus";
import AreaSelector from "@/components/kanji/AreaSelector";
import OpenShopWarning from "../../OpenShopWarning";

export default {
    name: "ShopList",
    components: {
        OpenShopWarning,
        AreaSelector,
        Carousel,
        Slide
    },
    mounted() {
        this.getShop();
        this.getArea();
        EventBus.$on('localeChange', () => {
            this.getShop();
            this.getArea();
        })
    },
    computed: {
        shopParts: function () {
            let result = [[], []]
            for (let i = 0; i < this.shops.length; i++) {
                if (i < this.shops.length / 2) {
                    result[0].push(this.shops[i])
                } else {
                    result[1].push(this.shops[i])
                }
            }
            return result
        }
    },
    watch: {
        // areaId: function (value) {
        //     this.input.model.areaId = value;
        //     this.paging.page = 1;
        //     this.getShop()
        // },
        // provinceId: function (value) {
        //     this.input.model.provinceId = value;
        //     this.paging.page = 1;
        //     this.getShop()
        // },
        'input.model.placeName': function (value) {
            console.log("place change")
            if (!value) {
                this.$refs.areaSelector.resetData()
                this.hide = true
            } else {
                this.hide = false
            }
        }

    },
    props: {
        areaId: {
            type: Number,
            default: 0
        },
        provinceId: {
            type: Number,
            default: 0
        },
        categoryId: {
            type: Number
        },
        subCategoryId: {
            type: Number
        },
        categoryName: {
            type: String,
            defaults: ''
        },
        subCategoryName: {
            type: String,
            defaults: ''
        }
    },
    data() {
        return {
            input: {
                model: {
                    areaId: null,
                    provinceId: null,
                    placeName: null,
                    keyword: null
                }
            },
            popup: {
                areaSelector: {
                    show: false
                },
                openShopWarning: {
                    show: false,
                    item: null
                }
            },
            paging: {
                page: 1,
                limit: 10,
                total: 0
            },
            shops: [],
            areas: [],
            hide: true
        }
    },
    methods: {
        getShop: function () {
            ShopApi.searchShop(Object.assign({
                page: this.paging.page,
                limit: this.paging.limit,
                areaId: this.input.model.provinceId ? null : this.input.model.areaId,
                provinceId: this.input.model.provinceId,
                categoryId: (!this.subCategoryId && this.categoryId) ? this.categoryId : null,
                subCategoryId: this.subCategoryId || null,
            }, this.trimObject(this.input.model))).then(resp => {
                this.shops = resp.data
                this.paging.total = resp.total
            });
        },
        getArea: function () {
            AreaApi.area().then(resp => {
                this.areas = resp.data
            })
        },
        viewShop: function (shop) {
            ActionHistoryApi.addShopViewHistory(shop.id);
            setTimeout(() => {
                window.open(shop.linkedUrl)
            }, 500)
        },
        removeSelectedArea() {
            this.input.model.provinceId = null;
            this.input.model.areaId = null;
            this.input.model.placeName = null
        }
    }
}
</script>

<style scoped lang="scss">
@import "/src/style/variables";

.category {
    display: inline-block;
    cursor: pointer
}

.category:hover {
    text-decoration: underline;
}

.divider {
    height: 1px;
    background-color: #dcdfe6;
}

.card-wrapper {
    all: unset;
    color: unset;
    cursor: pointer;
}

.shop-image-wrapper {
    position: relative;
    width: 100%;
    padding-top: 80%;
}

.shop-image-slider {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%
}

.search {
    width: 100%;
}

::v-deep .VueCarousel-wrapper {
    height: 100%;
}

::v-deep .VueCarousel-inner {
    height: 100% !important;
}

::v-deep .VueCarousel-dot-container {
    margin-top: 0px !important;
}

::v-deep .VueCarousel-dot {
    margin-top: 0px !important;
}

.shop-image {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.shop-name {
    margin: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.shop-code {
    margin: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.shop-overseaSale {
    margin: 5px;
    text-align: right;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.shop-description {
    margin: 5px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.shop-item {
    display: flex;
    border: 1px solid #DFE0EB;
    box-sizing: border-box;
    border-radius: 6px;
    margin-top: 24px;
    height: 182px;
}

.shop-item div:first-child {
    width: 180px;

}

.btn-search-shop {
    position: absolute;
    right: 3px;
    height: 34px;
    bottom: 3px;
    padding: 0px 20px;
}

::v-deep .VueCarousel-pagination {
    //display: none;
    position: absolute;
    bottom: 0px;
}

.shop-filter {
    width: 320px;
}

.shop-filter-right {
    margin-left: 20px;
}


.breadcrumb-search {
    display: flex;
    justify-content: space-between;
}

::v-deep .VueCarousel-dot--active {
    background-color: map-get($color, bgPrimary) !important;
}

::v-deep .VueCarousel-dot {
    width: 8px !important;
    height: 8px !important;
}

.area-row {
    //display: flex;
    position: relative;
}

.area {
    width: 100%;
    border: 0;
}

.btn-inside {
    position: absolute;
    z-index: 2;
    width: 30px;
    height: 30px;
    right: 0.5em;
    top: 50%;
    transform: translateY(-50%);
}

::v-deep .hide {
    display: none !important;
}

@media screen and (max-width: 991px) { // pc

    .description1 {
        display: none;
    }

    .description2 {
        display: block;
    }
}

@media screen and (min-width: 992px) { // mobile
    .btn-search-shop {
        //position: absolute;
        right: 10px;
        //height: 34px;
        bottom: 11px;
        //padding: 0px 20px;
        height: 80px;
        padding-left: 35px;
        padding-right: 35px;
    }

    ::v-deep .btn-search-shop i {
        font-size: 40px !important;
    }

    .shop-filter {
        width: 100%;
    }

    .shop-filter-right {
        margin-left: 0px;
        margin-top: 10px;
    }

    .description2 {
        display: none;
    }

    .description1 {
        display: block;
    }

    .breadcrumb-search {
        display: block;
    }

}
</style>
