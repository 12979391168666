<template>
    <el-dialog :visible="value"
               class="redirect-warning"
               :title="$t('TEXT0213')"
               @close="$emit('input', false)">
        <div v-for="(item, index) in policies"
             style="margin-bottom: 20px; color: #4F4F4F"
             :class="item.class"
             :key="index">{{ $t(item.code) }}
        </div>
        <div style="text-align: center; margin-top: 20px">
            <el-button @click.prevent="()=>{$emit('ok', true); $emit('input', false); $emit('close', true)}"
                       type="primary">OK
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "OpenShopWarning",
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            policies: [{
                code: 'TEXT0209',
                class: 'text'
            }, {
                code: 'TEXT0210',
                class: 'text'
            }, {
                code: 'TEXT0211',
                class: 'text'
            }, {
                code: 'TEXT0212',
                class: 'text'
            }]
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog__title{
    display: block;
    margin-right: 30px;
    text-align: initial;
}

.h1 {
    font-size: 20px;
    font-weight: bold;
}

.h2 {
    font-size: 18px;
    font-weight: bold;
}

.n1 {
    font-size: 16px;
    font-weight: bold;
}

.n11 {
    font-size: 14px;
    font-weight: bold;
}

.text-bold {
    font-size: 14px;
    font-weight: bold;
}

.text {
    font-size: 14px;
    line-height: 25px;
    text-align: left;
}

.no-margin {
    margin-bottom: 0px !important;
}

.redirect-warning {
    overflow: hidden;

}

::v-deep .el-dialog{
    max-width: 700px !important;
}

::v-deep .el-dialog__body {
    overflow: auto !important;
    max-height: 60vh !important;
}


@media screen and (max-width: 992px) {
    // mobile
    ::v-deep .el-dialog {
        width: calc(100% - 40px);
        margin: 0px 20px;
    }

    ::v-deep .el-dialog__body::-webkit-scrollbar {
        width: 7px;
    }

    ::v-deep .el-dialog__body::-webkit-scrollbar-thumb {
        background-color: #888888; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        //border:1 px solid #555555; /* creates padding around scroll thumb */
    }
}
</style>
