<template>
    <div>
        <h3 style="margin: 10px;">{{ $t('TEXT0127') }}</h3>
        <div class="divider"></div>
        <Carousel navigationEnabled
                  loop
                  :navigationNextLabel="nextLabel"
                  :navigationPrevLabel="prevLabel"
                  :perPage="perPage">
            <Slide v-for="(item, index) in shops" :key="index">
                <el-card shadow="hover" :body-style="{ padding: '0px' }" style="margin: 10px;">

                    <div class="shop-image-wrapper">
                        <img class="shop-image" :src="item.thumbs[0] ? getApiBase() + item.thumbs[0].link : ''"/>
                    </div>

                    <div style="padding: 5px;">
                        <div style="height: 1.5em">
                            <a class="card-wrapper" href="#"
                               @click.prevent="popup.shopOpenWarning.show = true; popup.shopOpenWarning.item = item">
                                <h3 class="shop-name">
                                    {{ item.name }}</h3>
                            </a>
                        </div>
                        <div style="height: 1.5em">
                            <div class="shop-code">
                                {{ item.code }}
                            </div>
                        </div>
                        <div style="height: 3em">
                            <el-tooltip :content="item.description">
                                <div class="shop-description">
                                    {{ item.description }}
                                </div>
                            </el-tooltip>
                        </div>
                    </div>

                </el-card>
            </Slide>
        </Carousel>
        <OpenShopWarning v-model="popup.shopOpenWarning.show" @ok="viewShop(popup.shopOpenWarning.item)"/>
    </div>
</template>

<script>
import ShopApi from "@/api/ShopApi";
import {Carousel, Slide} from 'vue-carousel';
import ActionHistoryApi from "@/api/ActionHistoryApi";
import EventBus from "@/config/EventBus";
import OpenShopWarning from "../../OpenShopWarning";

export default {
    name: "SuggestShop",
    components: {
        OpenShopWarning,
        Carousel,
        Slide
    },
    computed: {
        window: function () {
            return window;
        }
    },
    mounted() {
        this.getSuggestShop()
        if (window.innerWidth >= 1700) {
            this.perPage = 6
        } else if (window.innerWidth >= 1200) {
            this.perPage = 5
        } else if (window.innerWidth >= 992) {
            this.perPage = 4
        } else if (window.innerWidth >= 768) {
            this.perPage = 3
        } else {
            this.perPage = 2
        }
        EventBus.$on('localeChange', () => {
            this.getSuggestShop()
        })
    },
    watch: {
        'shops': function (val) {
            if (val.length <= 0) {
                this.$emit('setHide', this.shops.length <= 0)
            }
        }
    },
    data: function () {
        return {
            shops: [],
            popup: {
                shopOpenWarning: {
                    show: false,
                    item: null
                }
            },
            perPage: 5,
            nextLabel: '<span class="fa fa-angle-right fa-2x" style="color: #BDBDBD; border: 1px solid #BDBDBD; border-radius: 50%; width: 26px; height: 26px; font-weight: 100; background-color: white"></span>',
            prevLabel: '<span class="fa fa-angle-left fa-2x" style="color: #BDBDBD; border: 1px solid #BDBDBD; border-radius: 50%; width: 25px; height: 25px; font-weight: 100; background-color: white"></span>'
        }
    },
    methods: {
        getSuggestShop: function () {
            ShopApi.getSuggestShop().then(resp => {
                this.shops = resp.data
            }).finally(() => {
                this.$emit('setHide', this.shops.length <= 0)
            });
        },
        viewShop: function (shop) {
            ActionHistoryApi.addShopViewHistory(shop.id);
            setTimeout(() => {
                window.open(shop.linkedUrl)
            }, 500)

        }
    }
}
</script>

<style scoped lang="scss">

.divider {
    height: 1px;
    background-color: #dcdfe6;
    margin: 10px;
}

.card-wrapper {
    all: unset;
    color: unset;
    cursor: pointer;
}

.shop-image-wrapper {
    position: relative;
    width: 100%;
    padding-top: 80%;
}

.shop-image {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%
}

.shop-name {
    margin: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.shop-code {
    margin: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.shop-description {
    margin: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


::v-deep .VueCarousel-dot-container {
    display: none;
}

::v-deep .VueCarousel-pagination {
    display: none;
}

::v-deep .VueCarousel-navigation-next {
    right: 32px !important;
}

::v-deep .VueCarousel-navigation-prev {
    left: 32px !important;
}

</style>
