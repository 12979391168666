<template>
    <div>
        <img class="animation" :src="input.data.startImage" v-if="pc"/>
        <img class="animation" :class="{ hide: input.displayEn }" :src="input.data.startImageEn" v-if="!pc"/>
        <img class="animation" :class="{ hide: input.displayJp }" :src="input.data.startImageJP" v-if="!pc"/>
    </div>
</template>

<script>
import {isMobile} from "mobile-device-detect";
import EventBus from "@/config/EventBus";

export default {
    name: "Notification",
    props: {
        title: {
            type: String,
            required: true
        },
        message: {
            type: String,
            required: true
        },
        submitText: {
            type: String,
            required: true
        }
    },
    mounted() {
        this.getAnimation()
        EventBus.$on('localeChange', () => {
            this.getAnimation()
        });
    },
    data() {
        return {
            input: {
                data: {
                    startImage: require('@/assets/gif/ListStore_PC.gif'),
                    startImageEn: require('@/assets/gif/listStpre_mobile_EN.gif'),
                    startImageJP: require('@/assets/gif/listStore_mobile_jp.gif'),
                },
                styleEn: {
                    hideEn: this.hideEn
                },
                styleJp: {
                    hideJp: this.hideJp
                },
                hideEn: true,
                hideJp: false
            },
            pc: false
        }
    },
    methods: {
        getAnimation: function () {
            if (!isMobile) {
                this.pc = true
                this.input.data.startImage = require('@/assets/gif/ListStore_PC.gif') + '?a=' + Math.random();
            } else if ((JSON.parse(localStorage.getItem('lang') || '{}').locale || 'en-us').toLocaleLowerCase() == 'en-us') {
                this.input.displayEn = false
                this.input.displayJp = true
                this.input.data.startImageEn = require('@/assets/gif/listStpre_mobile_EN.gif') + "?a=" + Math.random();
            } else {
                this.input.displayEn = true
                this.input.displayJp = false
                this.input.data.startImageJP = require('@/assets/gif/listStore_mobile_jp.gif') + "?a=" + Math.random();
            }
        },
    }
}
</script>

<style scoped lang="scss">
.animation {
    margin: auto;
    display: block;
    padding: 10px;
    max-height: 500px;
}

.hide {
    display: none;
}

@media screen and (max-width: 992px) { // mobile
    .animation {
        max-width: 100%;
        padding: 0;
    }
}

</style>
